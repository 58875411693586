import { TextType } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import googlePlay from 'helpers/paymentFlow/img/googlePlay.webp';
import apple from 'helpers/paymentFlow/img/apple.webp';

import { Image, Text } from 'libComponents';

import styles from './styles.module.css';

const StoresItems = ({
    gap,
    maxWidth = 33,
    textType = 'caption',
}: {
    gap?: number;
    maxWidth?: number;
    textType?: TextType;
}) => {
    const { t } = useTranslation();

    const stores = [
        {
            name: t('basics.appStore'),
            image: apple,
        },
        {
            name: t('basics.googlePlay'),
            image: googlePlay,
        },
    ];

    return (
        <div className={styles.storesList} style={{ gap: gap }}>
            {stores.map(({ name, image }, index) => (
                <div className={styles.storesList__item} key={index}>
                    <Image src={image} maxWidth={maxWidth} alt={name} />
                    <Text type={textType} color="text-secondary" text={name} />
                </div>
            ))}
        </div>
    );
};

export default StoresItems;
