import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';

import { DAYS_PER_WEEK, PER_WEEK } from 'constants/payments';

import { getPriceFromCents } from 'helpers/utils';
import { getSubscriptionTitle } from 'helpers/subscriptions';

import { UseGetCardsConfigureType } from './types';

const getPeriod = ({ period, trial, t }: { period: string; trial: number; t: TFunction<'translation'> }) => {
    const isOneWeekTrial = trial === DAYS_PER_WEEK;

    const legalDisclaimer =
        period === PER_WEEK && isOneWeekTrial ? 'paymentFlow.G4Foxtrot16.firstWeek' : 'paymentFlow.foxtrot16.perPeriod';

    return t(legalDisclaimer, { context: period });
};

export const useGetCardsConfigure: UseGetCardsConfigureType = (products) => {
    const { t } = useTranslation();

    return products.map(
        (
            {
                trial,
                id,
                period,
                price_per_state,
                start_price_per_state,
                price,
                start_price,
                is_preselected,
                product_state,
            },
            index
        ) => {
            const isTrial = Boolean(trial && trial !== period);

            return {
                isPopular: index === 1,
                isTrial,
                trial: trial,
                id: id,
                period: period,
                pricePerState: price_per_state,
                oldPricePerState: (start_price_per_state / 100).toFixed(2),
                fullPrice: getPriceFromCents(price),
                startFullPrice: getPriceFromCents(start_price),
                isPreselected: is_preselected,
                withFullPrice: true,
                perState: getPeriod({ period: product_state, trial, t }),
                label:
                    isTrial &&
                    t('paymentFlow.priceCard.period', {
                        count: +getSubscriptionTitle(period)?.count,
                        context: getSubscriptionTitle(period)?.context,
                    }),
            };
        }
    );
};
