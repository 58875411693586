import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { useEffect, useState } from 'react';

import { selectCurrency } from 'redux/payment/selectors';

import { processingFeeConvertor } from 'helpers/utils';

import { Text } from 'libComponents';

import type { PaymentDisclaimerPropsType } from './types';

function PaymentDisclaimer({
    currentPaymentType,
    paymentLabel,
    fullPrice,
    customDisclaimer,
    isProcessingFee,
    trialPrice,
    timerTime,
    isTrial = false,
}: PaymentDisclaimerPropsType) {
    const { sign } = useSelector(selectCurrency);

    const [disclaimer, setDisclaimer] = useState('');

    const getDisclaimerWithWeekTrialBeforeTimer = (isTrial: boolean) =>
        isTrial ? 'basics.paymentFlow.disclaimerTrial.timerOn' : 'basics.paymentFlow.disclaimer.timerOn';

    const getDisclaimerWithWeekTrialAfterTimer = (isTrial: boolean) =>
        isTrial ? 'basics.paymentFlow.disclaimerTrial.timerOff' : 'basics.paymentFlow.disclaimer.timerOff';

    useEffect(() => {
        if (customDisclaimer) return;
        if (timerTime > 0) setDisclaimer(getDisclaimerWithWeekTrialBeforeTimer(isTrial));
        else setDisclaimer(getDisclaimerWithWeekTrialAfterTimer(isTrial));
    }, [timerTime, isTrial, customDisclaimer]);

    return (
        <Text center type="caption" color="text-secondary-subdued">
            <Trans
                i18nKey={customDisclaimer || disclaimer}
                values={{
                    fullPrice: isProcessingFee && fullPrice ? processingFeeConvertor(fullPrice) : fullPrice,
                    trialPrice,
                    currency: sign,
                    count1: currentPaymentType?.count,
                    count2: paymentLabel?.count,
                    context1: currentPaymentType?.context,
                    context2: paymentLabel?.context,
                }}
            />
        </Text>
    );
}

export default PaymentDisclaimer;
