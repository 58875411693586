import { ActionEventAction, ElementEventAction } from 'wikr-core-analytics';

import { EventDataTrack } from '../types';

import { trackClick, trackEventFixed } from './mainTrackers';

export const sendAnalyticOptionEventLabel = (
    eventLabel?: string | string[] | object,
    screenIdParam?: string,
    data?: Record<string, unknown>
) => {
    trackClick({
        elementEvent: ElementEventAction.option,
        actionEvent: ActionEventAction.continue,
        eventLabel,
        screenIdParam,
        data,
    });
};

export const sendAnalyticButtonEventLabel = (eventLabel: string | string[] | object, screenIdParam?: string) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.continue,
        eventLabel,
        screenIdParam,
    });
};

export const sendAnalyticButtonSkip = ({
    eventLabel,
    screenIdParam,
}: {
    eventLabel?: string | string[] | object;
    screenIdParam?: string;
}) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.skip,
        screenIdParam,
        eventLabel,
    });
};

export const sendAnalyticTextSkip = () => {
    trackClick({
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.skip,
    });
};

export const sendAnalyticTextSkipEventLabel = (eventLabel: string | string[] | object) => {
    trackClick({
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.skip,
        eventLabel,
    });
};

export const sendAnalyticButtonContinueEventLabel = (
    eventLabel: string | string[] | object,
    screenIdParam?: string
) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.continue,
        eventLabel,
        screenIdParam,
    });
};

export const sendAnalyticButtonContinue = (screenIdParam?: string) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.continue,
        screenIdParam,
    });
};

export const sendAnalyticCheckboxSelect = (screenIdParam?: string) => {
    trackClick({
        // TODO: rm after lib extends actions and elements types for events
        // @ts-ignore
        elementEvent: 'checkbox_terms_policies',
        actionEvent: ActionEventAction.select,
        screenIdParam,
    });
};

export const sendAnalyticOption = () => {
    trackClick({
        elementEvent: ElementEventAction.option,
        actionEvent: ActionEventAction.continue,
    });
};

export const sendAnalyticLogin = () => {
    // TODO: rm after lib extends actions and elements types for events
    // @ts-ignore
    trackClick({ elementEvent: 'text__header', actionEvent: 'login' });
};

export const sendAnalyticProcessorFromLoad = (data: EventDataTrack) => {
    trackEventFixed({
        eventData: 'processor_form_load',
        data,
    });
};

export const sendAnalyticLead = () =>
    trackEventFixed({
        eventData: 'Lead',
    });

export const sendAnalyticRegistration = () =>
    trackEventFixed({
        eventData: 'Registration',
    });

export const sendAnalyticSignUp = () =>
    trackEventFixed({
        eventData: 'SIGN_UP',
    });

export const sendAnalyticOptionSelectEventLabel = (eventLabel: string | string[] | object, screenIdParam?: string) => {
    trackClick({
        elementEvent: ElementEventAction.option,
        actionEvent: ActionEventAction.select,
        eventLabel,
        screenIdParam,
    });
};

export const sendAnalyticHeaderScrollEventLabel = (eventLabel: string | string[] | object) => {
    trackClick({
        elementEvent: ElementEventAction.header,
        actionEvent: ActionEventAction.scroll,
        eventLabel,
    });
};

export const sendAnalyticButtonScrollEventLabel = (eventLabel: string | string[] | object) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.scroll,
        eventLabel,
    });
};

export const sendAnalytiLinkSelectEventLabel = (eventLabel: string | string[] | object, screenIdParam?: string) => {
    trackClick({
        elementEvent: ElementEventAction.link,
        actionEvent: ActionEventAction.select,
        eventLabel,
        screenIdParam,
    });
};

export const sendAnalyticScreenClose = (screenIdParam?: string) => {
    trackClick({
        elementEvent: ElementEventAction.screen,
        actionEvent: ActionEventAction.close,
        screenIdParam,
    });
};

export const sendAnalyticButtonSelectEventLabel = (screenIdParam?: string) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.select,
        screenIdParam,
    });
};

export const sendAnalyticPopupLoad = (screenIdParam?: string) => {
    trackClick({
        elementEvent: ElementEventAction.popup,
        actionEvent: ActionEventAction.load,
        screenIdParam,
    });
};

export const sendAnalyticPopupClose = (screenIdParam?: string) => {
    trackClick({
        elementEvent: ElementEventAction.popup,
        actionEvent: ActionEventAction.close,
        screenIdParam,
    });
};

export const sendAnalyticPopupContinue = ({
    screenIdParam,
    eventLabel,
}: {
    screenIdParam?: string;
    eventLabel?: string;
} = {}) => {
    trackClick({
        elementEvent: ElementEventAction.button,
        actionEvent: ActionEventAction.continue,
        screenIdParam,
        eventLabel,
    });
};

// TODO Remove the ts-ignore once we've moved event action typing to the project side
export const sendAnalyticToggleAccessOnAnyDevice = (isActive: boolean, screenIdParam?: string) => {
    trackClick({
        // @ts-ignore
        elementEvent: 'toggle',
        // @ts-ignore
        actionEvent: isActive ? 'turnon' : 'turnoff',
        screenIdParam,
    });
};

export const sendAnalyticPolicyCheckboxClick = (screenIdParam?: string) => {
    trackClick({
        // TODO: rm after lib extends actions and elements types for events
        // @ts-ignore
        elementEvent: 'policy_consent',
        // @ts-ignore
        actionEvent: 'consent_provided__click',
        screenIdParam,
    });
};

export const sendAnalyticResetContactUsClick = () => {
    trackClick({
        elementEvent: ElementEventAction.text,
        actionEvent: ActionEventAction.contactUs,
    });
};
