export const resultRoutes = [
    {
        path: 'ResultPages/ResultA',
        route: 'result',
    },
    {
        path: 'ResultPages/ResultFull',
        route: 'result-full',
    },
    {
        path: 'ResultPages/ObResultMeditation',
        route: 'ob-result-meditation',
    },
    {
        path: 'ResultPages/ResultFullDes',
        route: 'result-full-des',
    },
    {
        path: 'ResultPages/ResultFullNew',
        route: 'result-full-new',
    },
];
